import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import "@/scss/main.scss";

import vClickOutside from "v-click-outside";
Vue.use(vClickOutside);

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor /* { default global options } */);

import VueResize from "vue-resize";
import "vue-resize/dist/vue-resize.css";
Vue.use(VueResize);

import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

import VueMask from "v-mask";
Vue.use(VueMask);

import VueSanitize from "vue-sanitize";
let defaultOptions = {
  allowedTags: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "blockquote",
    "p",
    "span",
    "a",
    "ul",
    "ol",
    "nl",
    "li",
    "b",
    "i",
    "s",
    "u",
    "strong",
    "em",
    "strike",
    "code",
    "hr",
    "br",
    "div",
    "table",
    "thead",
    "caption",
    "tbody",
    "tr",
    "th",
    "td",
    "pre",
    "img"
  ],
  allowedAttributes: {
    "*": ["class", "style"],
    a: ["href", "name", "target"],
    img: ["src"],
    iframe: ["src", "frameborder", "allowfullscreen"],
    pre: ["spellcheck"],
    ul: ["data-checked"]
  }
};
Vue.use(VueSanitize, defaultOptions);
// Vue.use(VueSanitize);

Vue.config.productionTip = false;

// https://ticket-system.royalclub.cash/
// https://ticket-system-staging.stage.rccore.net/
export const BASE_URL = process.env.VUE_APP_BASE_URL;

import Echo from "laravel-echo";
// eslint-disable-next-line no-unused-vars
import Pusher from "pusher-js";

const echo = new Echo({
  broadcaster: "pusher",
  cluster: "eu",
  // TODO: поменять ключ для master
  // production
  key: process.env.VUE_APP_ECHO_KEY
  // secret: "36479d208afacc743a69"

  // staging
  // key: "5e9a5e965819142508c5"
  // secret: "4cd3695dc84f1b2a0c58"
});

Vue.prototype.$echo = echo;

export const API = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

export const eventBus = new Vue();

API.interceptors.request.use(
  config => {
    const tokenSupport = localStorage.getItem("support-token");
    if (tokenSupport) {
      config.headers.common["Authorization"] = "Bearer " + tokenSupport;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response.status === 401) {
      store.commit("oauth/AUTH_LOGOUT");
    }
    if (error.response.status === 403) {
      store.commit("oauth/REDIRECT");
    }
    if (error.response.status >= 400 && error.response.status < 600) {
      return Promise.reject(error.response);
    }
  }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
