<template>
  <div class="tickets">
    <div v-if="loadingData" class="loading-component" />
    <div class="tickets__nav">
      <div class="tickets__menu">
        <button id="menuOpen"></button>

        <div class="tickets__theme">
          <p>
            {{ params.theme ? getThemeById(params.theme).name : "Все" }}
          </p>

          <span>
            {{ params.theme ? getThemeById(params.theme).count : total }}
          </span>
        </div>
      </div>

      <div class="tickets__search">
        <div class="input-container">
          <img src="../assets/icons/search.svg" alt="" />

          <input
            v-model="params.search"
            @keyup.enter="handleChange('search')"
            type="text"
            placeholder="Поиск"
          />

          <label>
            <select
              id="search-type"
              @change="handleChangeSearch"
              v-model="params.searchType"
            >
              <option value="ticket" selected>
                По тикетам
              </option>
              <option value="message">По сообщениям</option>
            </select>

            <svg
              width="6"
              height="4"
              viewBox="0 0 6 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L3 3L5 1" stroke="black" />
            </svg>
          </label>
        </div>

        <button @click="$router.push('/')">
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.19994 0.401602C6.26362 0.337759 6.33928 0.287106 6.42256 0.252545C6.50585 0.217985 6.59514 0.200195 6.68531 0.200195C6.77549 0.200195 6.86478 0.217985 6.94806 0.252545C7.03135 0.287106 7.10701 0.337759 7.17069 0.401602L11.284 4.51493C11.3479 4.57861 11.3985 4.65426 11.4331 4.73755C11.4676 4.82084 11.4854 4.91013 11.4854 5.0003C11.4854 5.09048 11.4676 5.17976 11.4331 5.26305C11.3985 5.34634 11.3479 5.42199 11.284 5.48567L7.17069 9.599C7.04196 9.72773 6.86736 9.80005 6.68531 9.80005C6.50326 9.80005 6.32867 9.72773 6.19994 9.599C6.07121 9.47027 5.99889 9.29568 5.99889 9.11363C5.99889 8.93158 6.07121 8.75698 6.19994 8.62825L9.82927 5.0003L6.19994 1.37235C6.1361 1.30867 6.08545 1.23301 6.05089 1.14973C6.01633 1.06644 5.99854 0.977149 5.99854 0.886975C5.99854 0.796801 6.01633 0.707512 6.05089 0.624224C6.08545 0.540936 6.1361 0.465284 6.19994 0.401602Z"
              fill="#221122"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.515381 5.00025C0.515381 4.81843 0.587609 4.64406 0.716175 4.51549C0.844742 4.38693 1.01911 4.3147 1.20094 4.3147H10.1131C10.295 4.3147 10.4693 4.38693 10.5979 4.51549C10.7265 4.64406 10.7987 4.81843 10.7987 5.00025C10.7987 5.18207 10.7265 5.35645 10.5979 5.48501C10.4693 5.61358 10.295 5.68581 10.1131 5.68581H1.20094C1.01911 5.68581 0.844742 5.61358 0.716175 5.48501C0.587609 5.35645 0.515381 5.18207 0.515381 5.00025Z"
              fill="#221122"
            />
          </svg>
        </button>
      </div>

      <div class="tickets__date">
        <date-range-picker
          v-model="dateRange"
          opens="right"
          :locale-data="localeData"
          @update="updateDate"
        ></date-range-picker>

        <button @click="resetFilters">Сбросить</button>
      </div>

      <div class="tickets__selects">
        <div class="select-container">
          <label for="support">
            Исполнитель
            <select
              v-model="params.support"
              name="support"
              id="support"
              @change="handleChange('support')"
            >
              <option value="" selected>Все</option>
              <option
                :value="support.id"
                v-for="support in supports"
                :key="support.id"
              >
                {{ support.name }}
              </option>
            </select>

            <svg
              width="6"
              height="4"
              viewBox="0 0 6 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L3 3L5 1" stroke="black" />
            </svg>
          </label>
        </div>

        <div class="select-container">
          <label for="status">
            Статус
            <select
              name="status"
              id="status"
              @change="handleChange('status')"
              v-model="params.status"
            >
              <option value="" selected>Все</option>
              <option
                :value="status.status"
                v-for="status in statuses"
                :key="status.status"
              >
                {{ status.status }}
              </option>
            </select>

            <svg
              width="6"
              height="4"
              viewBox="0 0 6 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L3 3L5 1" stroke="black" />
            </svg>
          </label>
        </div>

        <div class="select-container">
          <label for="with_answer">
            Ответ
            <select
              v-model="params.with_answer"
              name="with_answer"
              id="with_answer"
              @change="handleChange('with_answer')"
            >
              <option value="" selected>Все</option>
              <option :value="1">С ответом</option>
              <option :value="0">Без ответа</option>
            </select>

            <svg
              width="6"
              height="4"
              viewBox="0 0 6 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L3 3L5 1" stroke="black" />
            </svg>
          </label>
        </div>

        <div class="select-container">
          <label for="order">
            Порядок
            <select
              name="order"
              id="order"
              @change="handleChange('order')"
              v-model="params.order"
            >
              <option value="desc" selected>От новых к старым</option>
              <option value="asc">От старых к новым</option>
            </select>

            <svg
              width="6"
              height="4"
              viewBox="0 0 6 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L3 3L5 1" stroke="black" />
            </svg>
          </label>
        </div>

        <div class="select-container">
          <label for="source">
            Источник
            <select
              name="source"
              id="source"
              @change="handleChange('source')"
              v-model="params.source"
            >
              <option value="">Все</option>
              <option
                :value="source.id"
                v-for="source in sources"
                :key="source.id"
              >
                {{ source.name }}
              </option>
            </select>

            <svg
              width="6"
              height="4"
              viewBox="0 0 6 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L3 3L5 1" stroke="black" />
            </svg>
          </label>
        </div>
      </div>

      <div class="tickets__checkbox">
        <div class="checkbox-container">
          <input
            v-model="params.self"
            type="checkbox"
            id="self"
            name="self"
            @change="handleChange('self')"
          />
          <label for="self">Показывать только мои тикеты</label>
        </div>
      </div>
    </div>

    <div class="tickets__list">
      <div
        class="tickets__wrapper"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="busy"
        infinite-scroll-distance="0"
      >
        <ticket-card
          v-for="ticket in tickets"
          :key="ticket.id"
          :ticket="ticket"
          :disabled="isAllRequestLoading"
          @click.native="goToTicket(ticket.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TicketCard from "@/components/TicketCardSupport";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { eventBus } from "@/main";

export default {
  name: "Tickets",
  components: {
    TicketCard,
    DateRangePicker
  },

  data() {
    return {
      loadingData: false,
      busy: false,

      dateRange: {
        startDate: null,
        endDate: null
      },

      localeData: {
        firstDay: 1,
        format: "dd-mm-yyyy",
        separator: "/"
      },

      params: {
        status: "",
        with_answer: "",
        support: "",
        theme: "",
        order: "desc",
        page: 1,
        searchType: "ticket",
        search: "",
        self: "",
        source: ""
      }
    };
  },

  computed: {
    ...mapState({
      support: state => state.oauth.support,
      loading: state => state.ticket.loading,
      tickets: state => state.ticket.tickets,
      sources: state => state.source.sources,
      statuses: state => state.status.statuses,
      supports: state => state.user.users,
      loadingTickets: state => state.ticket.loadingTickets,
      lastPage: state => state.ticket.lastPage,
      total: state => state.ticket.total,
      ticketIsLoading: state => state.ticket.isLoadingTicket,
      ticketListIsLoading: state => state.ticket.isLoadingTicketList,
      messageLoading: state => state.message.messageIsLoading,
      presetsIsLoading: state => state.message.presetsIsLoading
    }),

    ...mapGetters({
      getThemeById: "theme/getThemeById"
    }),

    isAllRequestLoading() {
      return (
        this.ticketIsLoading ||
        this.messageLoading ||
        this.ticketListIsLoading ||
        this.presetsIsLoading
      );
    }
  },

  methods: {
    ...mapMutations({
      addTicket: "ticket/ADD_TICKET",
      updateTicket: "ticket/UPDATE_TICKET"
    }),

    ...mapActions({
      loadTickets: "ticket/loadTickets",
      loadStatuses: "status/loadStatuses",
      loadSources: "source/loadSources",
      loadUsers: "user/loadUsers"
    }),

    goToTicket(ticketId) {
      if (this.isAllRequestLoading) {
        return;
      }

      this.$router.push(`/${ticketId}`);
    },

    loadMore() {
      if (this.isAllRequestLoading) {
        return;
      }

      if (this.params.page < this.lastPage) {
        this.busy = true;
        this.params.page++;

        this.loadTickets(this.params)
          .then(() => {
            if (this.lastPage !== this.params.page) {
              this.busy = false;
            }
          })
          .catch(() => {
            this.busy = false;
          });
      }
    },

    resetFilters() {
      this.loadingData = true;
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.params = {
        status: "",
        with_answer: "",
        support: "",
        theme: "",
        order: "desc",
        page: 1,
        searchType: "ticket",
        search: "",
        self: "",
        source: ""
      };

      this.params.date = [];
      this.$router.replace({
        query: {}
      });

      this.loadTickets(this.params)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },

    updateDate() {
      this.loadingData = true;

      this.params.date = [
        this.dateRange.startDate.toLocaleDateString(),
        this.dateRange.endDate.toLocaleDateString()
      ];

      const dayStart = String(this.dateRange.startDate.getDate()).padStart(
        2,
        "0"
      );
      const monthStart = String(
        this.dateRange.startDate.getMonth() + 1
      ).padStart(2, "0");
      const yearStart = this.dateRange.startDate.getFullYear();

      const formattedDateStart = `${dayStart}.${monthStart}.${yearStart}`;

      const dayEnd = String(this.dateRange.endDate.getDate()).padStart(2, "0");
      const monthEnd = String(this.dateRange.endDate.getMonth() + 1).padStart(
        2,
        "0"
      );
      const yearEnd = this.dateRange.endDate.getFullYear();

      const formattedDateEnd = `${dayEnd}.${monthEnd}.${yearEnd}`;

      const newQuery = {
        ...this.$route.query,
        start: formattedDateStart,
        end: formattedDateEnd
      };

      if (JSON.stringify(this.$route.query) !== JSON.stringify(newQuery)) {
        this.$router.replace({ query: newQuery });
      }

      this.loadTickets(this.params)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },

    handleChangeSearch() {
      this.params.searchType = document.getElementById("search-type").value;

      const newQuery = {
        ...this.$route.query,
        searchType: this.params.searchType
      };

      if (JSON.stringify(this.$route.query) !== JSON.stringify(newQuery)) {
        this.$router.replace({ query: newQuery });
      }
    },

    saveQuery(type) {
      const validKeys = [
        "search",
        "support",
        "status",
        "with_answer",
        "order",
        "source",
        "self"
      ];

      // Проверка на валидный тип
      if (!validKeys.includes(type)) {
        console.warn(`Unknown query type: ${type}`);
        return;
      }

      let newValue;

      // Логика для каждого типа
      if (type === "search") {
        newValue = this.params.search;
      } else if (type === "self") {
        // Если чекбокс отмечен, добавляем значение, иначе удаляем
        const checkbox = document.getElementById(type);
        if (checkbox?.checked) {
          document.getElementById("support").value = this.support?.id || "";
          newValue = this.support?.id || "";
        } else {
          document.getElementById("support").value = "";
          newValue = "";
        }
      } else {
        newValue = document.getElementById(type)?.value;
      }

      // Если значение не найдено
      if (newValue === undefined) {
        console.warn(`No value found for type: ${type}`);
        return;
      }

      // Создаем новый объект query
      const newQuery = {
        ...this.$route.query,
        [type]: newValue
      };

      // Обновляем маршрут только при изменении
      if (JSON.stringify(this.$route.query) !== JSON.stringify(newQuery)) {
        this.$router.replace({ query: newQuery });
      }
    },

    handleChange(type, value) {
      this.loadingData = true;
      this.params.page = 1;

      this.saveQuery(type);

      if (type !== "search" && type !== "self" && type !== "theme") {
        this.params[type] = document.getElementById(type).value;

        if (type === "status") {
          eventBus.$emit("changeStatusFilter", {
            name: document.getElementById(type).value
          });
        }

        if (type === "support") {
          document.getElementById("self").checked = false;
        }
      }

      if (type === "self") {
        if (document.getElementById(type).checked) {
          this.params.support = this.support.id;
          document.getElementById("support").value = this.support.id;
        } else {
          this.params.support = "";
          document.getElementById("support").value = "";
        }
      }

      if (type === "theme") {
        this.params[type] = value;
      }

      this.loadTickets(this.params)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },

    playSound(path) {
      const audio = new Audio(path);
      audio.play();
    },

    getInfo() {
      const query = this.$route.query;

      this.params = Object.assign({ ...this.params }, query);

      const backendParams = this.formatParamsForBackend(this.params);

      this.loadTickets(backendParams).catch(() => {
        this.resetFilters();
      });
    },

    formatParamsForBackend(params) {
      const formattedParams = {
        ...params, // Копируем текущие параметры
        date: [] // Добавляем массив для дат
      };

      // Преобразуем with_answer в числовое значение
      if (formattedParams.with_answer === "true") {
        formattedParams.with_answer = 1;
      } else if (
        formattedParams.with_answer === "false" ||
        !formattedParams.with_answer
      ) {
        formattedParams.with_answer = 0;
      }

      if (formattedParams.searchType === undefined) {
        formattedParams.searchType = "ticket";
      }

      if (formattedParams.self) {
        formattedParams.support = +formattedParams.self;
        delete formattedParams.self;
      }

      // Если указаны начальная и конечная даты, добавляем их в массив date[]
      if (params.start && params.end) {
        formattedParams.date.push(params.start, params.end);

        const [dayStart, monthStart, yearStart] = params.start.split(".");
        const dateStart = new Date(`${yearStart}-${monthStart}-${dayStart}`);

        const [dayEnd, monthEnd, yearEnd] = params.start.split(".");
        const dateEnd = new Date(`${yearEnd}-${monthEnd}-${dayEnd}`);

        this.dateRange.startDate = dateStart;
        this.dateRange.endDate = dateEnd;

        delete formattedParams.start;
        delete formattedParams.end;
      }

      // Удаляем пустые параметры (например, если status = "")
      for (const key in formattedParams) {
        if (formattedParams[key] === "" || formattedParams[key] === null) {
          delete formattedParams[key];
        }
      }

      return formattedParams;
    }
  },

  mounted() {
    this.getInfo();

    this.$echo
      .channel("tickets")
      .listen("TicketCreate", () => {
        this.params.page = 1;
        this.loadTickets(this.params);
      })
      .listen("TicketUpdate", data => {
        this.updateTicket(data.ticket);
        this.params.page += 1;
        this.loadTickets(this.params);
      });

    this.$echo.channel("client_message").listen("ClientMessageCreate", () => {
      this.playSound("./audio/icq.mp3");
    });
  },

  created() {
    this.loadSources();
    this.loadStatuses();
    this.loadUsers();

    eventBus.$on("changeTheme", data => {
      this.handleChange("theme", data.id);
    });

    eventBus.$on("changeStatus", data => {
      this.params.status = data.name;
      document.getElementById("status").value = data.name;
      this.handleChange("status", data.name);
    });

    eventBus.$on("selectUserTheme", data => {
      this.params.theme = data.theme;
      this.params.page = 1;
      this.params.search = data.phone;
      this.loadTickets(this.params);
    });
  }
};
</script>

<style lang="scss" scoped>
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";

.tickets {
  background: #f5f6fa;
  height: 100%;

  &__date {
    .vue-daterange-picker {
      width: 100%;
      margin-top: 10px;
    }

    display: flex;
    align-items: baseline;

    button {
      margin-left: 15px;
      border-radius: 4px;
      border: none;
      outline: none;
      padding: 6px 12px;
      font-size: 14px;
      color: $main;
      background-color: white;
    }
  }

  &__nav {
    padding: 15px 30px 15px 20px;
  }

  &__theme {
    display: flex;
  }

  &__menu {
    display: flex;
    align-items: center;

    p {
      display: flex;
      margin: 0 0 0 10px;
      font-size: 14px;
      line-height: 17px;
      color: rgba(34, 17, 34, 0.7);
      cursor: default;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      max-width: 300px;
    }

    span {
      background: $main;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      color: #ffffff;
      padding: 3px 6px;
      margin-left: 6px;
    }

    button {
      background-image: url("../assets/icons/menu.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__menu button,
  &__search button {
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s linear;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      transform: scale(1.05);
    }

    &:active {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.03);
      transform: scale(1);
    }
  }

  &__search {
    display: flex;
    margin-top: 10px;

    .input-container {
      position: relative;
      display: flex;
      width: 375px;
      margin-right: 12px;
      background-color: #fff;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        margin: auto;
      }

      input {
        padding: 0 18px 0 35px;
        color: #212121;
        font-size: 14px;
        width: 100%;
        height: 40px;
        background: #ffffff;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.03);
        border-radius: 4px;
        border: none;
        outline: none;

        &::placeholder {
          color: #c8c4c4;
        }
      }

      label {
        position: relative;
        height: 100%;
        margin-right: 16px;
        display: flex;
        align-items: center;

        &:before {
          content: "";
          position: absolute;
          border-left: 1px solid $label-four;
          left: -8px;
          top: 10px;
          height: 20px;
          width: 1px;
        }

        select {
          border: none;
          outline: none;
          font-size: 12px;
          appearance: none;
          margin-right: 4px;
          color: $label-third;
        }

        svg {
          path {
            stroke: $label-third;
          }
        }
      }
    }

    button {
      svg {
        opacity: 0.5;
        transform: rotate(-180deg);
        height: 13px;
        width: auto;
      }
    }
  }

  &__selects {
    display: flex;
    margin-top: 20px;
    overflow: auto;
    flex-wrap: wrap;

    .select-container {
      margin-bottom: 10px;

      &:not(:nth-child(3n + 3)) {
        margin-right: 10px;
      }

      label {
        font-size: 12px;
        color: $label-third;
        font-weight: 500;
        position: relative;
        display: flex;
        flex-direction: column;
      }

      svg {
        position: absolute;
        right: 15px;
        top: 35px;

        path {
          stroke: $label-second;
        }
      }

      select {
        height: 33px;
        padding: 0 14px;
        margin-top: 8px;
        background-color: #ebecf2;
        color: $label-second;
        border-radius: 4px;
        border: none;
        outline: none;
        font-size: 12px;
        width: 133px;
        appearance: none;
      }
    }
  }

  &__checkbox {
    margin-top: 15px;

    .checkbox-container {
      input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }

      label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        font-size: 12px;
        line-height: 14px;
        color: $label-third;
        font-weight: 500;
        cursor: pointer;
      }

      label::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 2px;
        margin-right: 0.5em;
        background: #ebecf2 no-repeat center center;
        background-size: 50% 50%;
      }

      input:checked + label::before {
        background-size: 10px 12px;
        background-image: url("../assets/icons/checkbox.svg");
      }

      input:focus + label::before {
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 20px 30px 20px;
    max-height: calc(100vh - 358px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
