import store from "@/store";
const USER_CHECK_METHOD = "oauth/checkUser";
const SUPPORT_CHECK_METHOD = "oauth/checkSupport";

export default function userCheck(to, from, next) {
  let CHECK_METHOD = "";
  let support = false;

  if (localStorage.getItem("support-token")) {
    support = true;
    CHECK_METHOD = SUPPORT_CHECK_METHOD;
  } else {
    support = false;
    CHECK_METHOD = USER_CHECK_METHOD;
  }

  store
    .dispatch(
      CHECK_METHOD,
      support
        ? {}
        : {
            id: localStorage.getItem("source")
          }
    )
    .then(() => {
      if (support) {
        next();
      } else {
        let user = store.state.oauth.user;
        if (!user.first_name || !user.phone) {
          next("/register");
        } else {
          next();
        }
      }
    })
    .catch(() => {
      next("/sign-in");
    });
}
