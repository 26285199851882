<template>
  <div id="ticketCreateModal" class="ticket-create" v-if="ticketCreateVisible">
    <div
      class="ticket-create__content content"
      v-click-outside="onClickOutside"
    >
      <div v-if="loading" class="loading-component" />
      <div class="content__header">
        <h2>{{ !themeID ? "Тема" : "Вопрос" }}</h2>

        <div class="ticket-create__close">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="openModal"
          >
            <path
              d="M2.19998 0.730891L6.99998 5.53089L11.8 0.745437C11.8945 0.649276 12.0079 0.573771 12.1331 0.523698C12.2583 0.473624 12.3925 0.450071 12.5273 0.454528C12.7917 0.471639 13.0408 0.584406 13.2282 0.77177C13.4156 0.959135 13.5283 1.20829 13.5454 1.47271C13.5467 1.60272 13.5216 1.73164 13.4716 1.85166C13.4216 1.97167 13.3478 2.08028 13.2545 2.17089L8.43998 6.99998L13.2545 11.8291C13.4436 12.0123 13.5483 12.2654 13.5454 12.5273C13.5283 12.7917 13.4156 13.0408 13.2282 13.2282C13.0408 13.4156 12.7917 13.5283 12.5273 13.5454C12.3925 13.5499 12.2583 13.5263 12.1331 13.4763C12.0079 13.4262 11.8945 13.3507 11.8 13.2545L6.99998 8.46907L2.21452 13.2545C2.12001 13.3507 2.00658 13.4262 1.8814 13.4763C1.75621 13.5263 1.622 13.5499 1.48725 13.5454C1.2179 13.5314 0.963286 13.4181 0.772566 13.2274C0.581847 13.0367 0.468542 12.7821 0.454521 12.5127C0.453205 12.3827 0.478312 12.2538 0.528318 12.1338C0.578325 12.0137 0.652189 11.9051 0.745431 11.8145L5.55998 6.99998L0.730885 2.17089C0.640269 2.07906 0.569093 1.9699 0.521609 1.84994C0.474126 1.72998 0.451309 1.60168 0.454521 1.47271C0.471633 1.20829 0.584399 0.959135 0.771764 0.77177C0.959129 0.584406 1.20828 0.471639 1.4727 0.454528C1.60641 0.448177 1.73999 0.469439 1.86511 0.516987C1.99024 0.564534 2.10423 0.637349 2.19998 0.730891Z"
              fill="#212121"
              fill-opacity="0.4"
            />
          </svg>
        </div>
      </div>

      <div class="content__main">
        <transition name="fade" mode="out-in">
          <ul class="content__list" v-if="!themeID">
            <li
              v-for="theme in themes"
              :key="theme.id"
              @click="selectTheme(theme.id, theme.has_subtopics)"
            >
              {{ theme.name }}
            </li>
          </ul>
        </transition>

        <transition name="fade--delay" mode="out-in">
          <ul class="content__list" v-if="themeID">
            <li
              v-for="subtopic in subtopics"
              :key="subtopic.id"
              @click.once="createTicketRequest(subtopic.id)"
            >
              {{ subtopic.name }}
            </li>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TicketCreate",
  data() {
    return {
      themeID: "",
      ticketCreateVisible: false,
      loading: false
    };
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
      themes: state => state.theme.themes,
      subtopics: state => state.theme.subtopics
    })
  },

  methods: {
    ...mapActions({
      loadUserThemes: "theme/loadUserThemes",
      loadSubtopics: "theme/loadSubtopics",
      createTicket: "ticket/createTicket",
      loadTickets: "ticket/loadTickets"
    }),

    selectTheme(id, has_subtopics) {
      this.loading = true;
      if (has_subtopics) {
        this.themeID = id;
        this.loadSubtopics(id)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.createTicketRequest(id);
      }
    },

    openModal() {
      this.themeID = "";
      this.ticketCreateVisible = !this.ticketCreateVisible;
    },

    onClickOutside() {
      this.openModal();
    },

    createTicketRequest(themeID) {
      this.loading = true;
      // TODO source id
      this.createTicket({
        themeID: themeID
        // text: text
      })
        .then(response => {
          this.loading = false;
          this.openModal();
          this.$router.push(`/${response.data.data.id}`);
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  mounted() {
    this.loadUserThemes();
  }
};
</script>

<style lang="scss" scoped>
.ticket-create {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;

  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  &__content {
    background-color: #ffffff;
    margin: 10% auto;
    max-width: 435px;
    min-height: 660px;
    max-height: 660px;
    border-radius: 4px;
    overflow: hidden;

    @media (max-width: 576px) {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }

  .content {
    &__header {
      text-align: center;
      background: #f5f6fa;
      padding: 24px;
      position: relative;

      h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: $label-main;
      }
    }

    &__main {
      overflow: auto;
      max-height: 590px;
      padding: 32px 24px 32px 24px;
    }

    &__list {
      list-style: none;
      margin: 0;
      max-height: 100%;
      padding: 0;

      li {
        margin-bottom: 16px;
        padding-bottom: 16px;
        padding-right: 16px;
        border-bottom: 1px solid #eeeeee;
        cursor: pointer;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          right: 0;
          top: 4px;
          background-image: url("../assets/icons/arrow-bottom.svg");
          background-size: 8px 8px;
          width: 8px;
          height: 8px;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
</style>
