import { API } from "@/main";

const state = {
  statuses: []
};

const getters = {
  getStatusByName: state => name => {
    return state.statuses.find(status => status.status === name) || 0;
  }
};

const actions = {
  loadStatuses({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/support/tickets/statuses")
        .then(response => {
          commit("SET_STATUSES", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_STATUSES(state, payload) {
    state.statuses = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
