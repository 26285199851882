import { API } from "@/main";

const state = {
  sources: []
};

const getters = {};

const actions = {
  loadSources({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/sources")
        .then(response => {
          commit("SET_SOURCES", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  checkSource(_, source) {
    return new Promise((resolve, reject) => {
      API.get(`api/sources/${source}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_SOURCES(state, payload) {
    state.sources = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
