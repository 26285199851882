<template>
  <div class="layout-mobile">
    <transition name="slide-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "UserLayout"
};
</script>

<style lang="scss" scoped>
.layout-mobile {
  height: 100vh;
}
</style>
