import { API } from "@/main";

const state = {
  themes: [],
  loadingThemes: "",
  subtopics: [],
  loadingSubtopics: ""
};

const getters = {
  getThemeById: state => id => {
    return state.themes.find(theme => theme.id === id);
  }
};

const actions = {
  loadThemes({ commit }) {
    commit("SET_LOADING", { type: "themes", value: "LOADING" });

    return new Promise((resolve, reject) => {
      API.get("api/support/themes")
        .then(response => {
          commit("SET_THEMES", response.data.data);
          commit("SET_LOADING", { type: "themes", value: "SUCCESS" });
          resolve(response);
        })
        .catch(error => {
          commit("SET_LOADING", { type: "themes", value: "ERROR" });
          reject(error);
        });
    });
  },
  loadUserThemes({ commit }) {
    commit("SET_LOADING", { type: "themes", value: "LOADING" });

    return new Promise((resolve, reject) => {
      API.get("api/clients/themes", {
        params: {
          source_id: localStorage.getItem("source")
        }
      })
        .then(response => {
          commit("SET_THEMES", response.data.data);
          commit("SET_LOADING", { type: "themes", value: "SUCCESS" });
          resolve(response);
        })
        .catch(error => {
          commit("SET_LOADING", { type: "themes", value: "ERROR" });
          reject(error);
        });
    });
  },
  loadSubtopics({ commit }, id) {
    commit("SET_LOADING", { type: "subtopic", value: "LOADING" });

    return new Promise((resolve, reject) => {
      API.get(`api/clients/themes/${id}`)
        .then(response => {
          commit("SET_SUBTOPICS", response.data);
          commit("SET_LOADING", { type: "subtopic", value: "SUCCESS" });
          resolve(response);
        })
        .catch(error => {
          commit("SET_LOADING", { type: "subtopic", value: "ERROR" });
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_THEMES(state, payload) {
    state.themes = payload;
  },
  SET_SUBTOPICS(state, payload) {
    state.subtopics = payload;
  },
  SET_LOADING(state, payload) {
    switch (payload.type) {
      case "themes":
        state.loadingThemes = payload.value;
        break;
      case "subtopic":
        state.loadingSubtopics = payload.value;
        break;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
