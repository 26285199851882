<template>
  <div
    class="ticket-card"
    :class="[
      ticket.status === 'done' ? 'ticket-card--close' : '',
      ticket.id === parseInt($route.params.id) ? 'ticket-card--selected' : ''
    ]"
  >
    <div class="ticket-card__title card-title">
      <p class="ticket-card-title__type">{{ ticket.theme }}</p>
    </div>

    <div class="ticket-card__text">
      <quill-editor
        :content="cleanMessage(ticket.text)"
        :options="messageOption"
        :disabled="true"
      ></quill-editor>
    </div>

    <div class="ticket-card__date">
      <p>{{ ticket.created_at }}</p>
    </div>

    <span class="ticket-card__new-message" v-if="ticket.has_new_messages">
      Новое сообщение
    </span>

    <span class="ticket-card__done" v-if="ticket.status === 'done'">
      Закрыт
    </span>

    <span
      class="ticket-card__in-progress"
      v-if="ticket.status === 'in-progress'"
    >
      В работе
    </span>

    <span class="ticket-card__new" v-if="ticket.status === 'new'">
      Открыт
    </span>
  </div>
</template>

<script>
export default {
  name: "TicketCardUser",
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      messageOption: {
        theme: "bubble",
        modules: {
          toolbar: false
        },
        formats: [
          "background",
          "bold",
          "color",
          "font",
          "code",
          "italic",
          "link",
          "size",
          "strike",
          "underline",
          "blockquote",
          "header",
          "indent",
          "list",
          "align",
          "direction",
          "code-block",
          "formula"
        ]
      }
    };
  },

  methods: {
    cleanMessage(message) {
      return this.$sanitize(message);
    }
  }
};
</script>

<style lang="scss">
.ticket-card {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 24px;
  margin-top: 24px;
  transition: transform 0.1s linear;
  cursor: pointer;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.03);
  position: relative;

  &__new-message {
    position: absolute;
    right: 10px;
    top: -10px;
    padding: 4px 12px;
    background-color: rgba(255, 218, 122, 0.7);
    font-size: 10px;
    border-radius: 4px;
  }

  &__done {
    position: absolute;
    left: 10px;
    top: -10px;
    padding: 4px 12px;
    background-color: rgba(225, 241, 216, 0.9);
    color: #88c268;
    font-size: 10px;
    border-radius: 4px;
  }

  &__in-progress {
    position: absolute;
    left: 10px;
    top: -10px;
    padding: 4px 12px;
    background-color: rgba(255, 243, 222, 0.9);
    color: #fba84c;
    font-size: 10px;
    border-radius: 4px;
  }

  &__new {
    position: absolute;
    left: 10px;
    top: -10px;
    padding: 4px 12px;
    background-color: rgba(235, 241, 255, 0.9);
    color: #3870ff;
    font-size: 10px;
    border-radius: 4px;
  }

  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(1);
  }

  &--close {
    opacity: 0.6;
  }

  &--selected {
    position: relative;
    left: 22px;
  }

  &__title {
    display: flex;
    margin-bottom: 10px;
  }

  &-title__name,
  &-title__type,
  &__text p {
    font-size: 14px;
    line-height: 17px;
  }

  &-title__type {
    color: $label-main;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-height: 17px;
  }

  &__text {
    .ql-editor {
      padding: 0;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 34px;
      color: $label-second;

      * {
        cursor: pointer;
      }
    }
  }

  &__date p {
    font-size: 12px;
    line-height: 14px;
    color: $label-third;
  }
}
</style>
