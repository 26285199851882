<template>
  <div class="layout">
    <div class="layout__aside">
      <tickets />
    </div>

    <div class="layout__main">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import Tickets from "@/components/TicketsUser";

export default {
  name: "UserLayout",
  components: {
    Tickets
  }
};
</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-columns: 470px 1fr;
  height: 100%;

  @media (min-width: 768px) and (max-width: 968px) {
    grid-template-columns: 350px 1fr;
  }

  &__aside {
    background-color: #f5f6fa;
  }
}
</style>
