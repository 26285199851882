<template>
  <div class="layout">
    <div class="layout__aside">
      <menu-support />

      <tickets />
    </div>

    <div class="layout__main">
      <transition name="slide-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MenuSupport from "@/components/MenuSupport";
import Tickets from "@/components/TicketsSupport";

export default {
  name: "SupportLayout",
  components: {
    MenuSupport,
    Tickets
  }
};
</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-columns: 470px 1fr;
  height: 100%;

  &__aside {
    background-color: #f5f6fa;
  }
}
</style>
