<template>
  <div class="tickets">
    <div class="tickets__create">
      <button @click="openModal">
        Новая заявка
      </button>
    </div>

    <div class="tickets__list">
      <div
        class="tickets__wrapper"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="busy"
        infinite-scroll-distance="100"
      >
        <ticket-card
          v-for="ticket in tickets"
          :key="ticket.id"
          :ticket="ticket"
          @click.native="openChat(ticket.id)"
        />
      </div>
    </div>

    <ticket-create ref="ticketCreateModal" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import TicketCard from "@/components/TicketCardUser";
import TicketCreate from "@/components/TicketCreate";

export default {
  name: "Tickets",
  components: {
    TicketCard,
    TicketCreate
  },

  data() {
    return {
      ticketCreateVisible: false,
      busy: false,

      params: {
        page: 1,
        user: true
      }
    };
  },

  computed: {
    ...mapState({
      tickets: state => state.ticket.tickets,
      loadingTickets: state => state.ticket.loadingTickets,
      lastPage: state => state.ticket.lastPage
    })
  },

  methods: {
    ...mapMutations({
      addTicket: "ticket/ADD_TICKET"
    }),

    ...mapActions({
      loadTickets: "ticket/loadTickets"
    }),

    openModal() {
      this.$refs.ticketCreateModal.openModal();
    },

    loadMore() {
      if (this.params.page < this.lastPage) {
        this.busy = true;
        this.params.page++;

        this.loadTickets(this.params)
          .then(() => {
            if (this.lastPage !== this.params.page) {
              this.busy = false;
            }
          })
          .catch(() => {
            this.busy = false;
          });
      }
    },

    openChat(id) {
      if (this.$route.params.id) {
        let idChat = this.$route.params.id;
        this.$echo.leave(`tickets.${idChat}`);
      }
      this.$router.push(`/${id}`).catch(() => {});
    }
  },

  mounted() {
    this.loadTickets(this.params);

    this.$echo
      .channel("tickets")
      .listen("TicketCreate", () => {
        this.params.page = 1;
        this.loadTickets(this.params);
      })
      .listen("TicketUpdate", () => {
        this.params.page = 1;
        this.loadTickets(this.params);
      });

    if (!localStorage.getItem("source")) {
      this.$router.push("/sign-in");
      localStorage.clear();
    }
  }
};
</script>

<style lang="scss" scoped>
.tickets {
  background: #f5f6fa;
  max-height: 100vh;

  &__nav {
    padding: 15px 30px 15px 20px;

    button {
      display: flex;
      align-items: center;
      padding: 10px;
      color: $label-second;
      background-color: white;

      svg {
        margin-right: 10px;
      }
    }
  }

  &__create {
    padding: 15px 20px 10px 20px;

    @media (max-width: 425px) {
      padding: 15px 15px 10px 15px;
    }

    button {
      width: 100%;
      padding: 16px 0;
      background-color: $label-main;
      font-weight: 500;
      color: $gold-main;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 20px 10px 20px;
    height: calc(100vh - 74px);
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 425px) {
      padding: 0 15px 10px 15px;
    }
  }

  button {
    font-size: 14px;
    line-height: 17px;
    border: none;
    outline: none;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.08s linear;

    &:hover {
      transform: scale(1.03);
    }

    &:active {
      transform: scale(1);
    }
  }
}
</style>
