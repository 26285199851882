import Vue from "vue";
import VueRouter from "vue-router";
import SupportLayout from "@/components/layouts/SupportLayout";
import UserLayout from "@/components/layouts/UserLayout";
import UserLayoutMobile from "@/components/layouts/UserLayoutMobile";
import userCheck from "@/router/middleware/userCheck";

Vue.use(VueRouter);

const routes = [
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn"),
    meta: { title: "Вход" }
  },
  {
    path: "/sign-in-support",
    name: "SignInSupport",
    component: () => import("@/views/SignInSupport"),
    meta: { title: "Вход в панель специалиста поддержки" }
  },
  {
    path: "/register",
    name: "RegisterUser",
    component: () => import("@/views/RegisterUser"),
    meta: { title: "Регистрация" }
  },
  {
    path: "/",
    component: localStorage.getItem("support-token")
      ? SupportLayout
      : window.innerWidth <= 768
      ? UserLayoutMobile
      : UserLayout,
    children: [
      {
        path: "",
        name: "Ticket-Empty",
        meta: {
          title: localStorage.getItem("support-token")
            ? "Панель специалиста поддержки"
            : "RC.Ticket - Служба поддержки"
        },
        component:
          localStorage.getItem("source") && window.innerWidth <= 768
            ? () => import("@/components/TicketsUser")
            : () => import("@/views/TicketEmpty")
      },
      {
        path: ":id",
        name: "Ticket",
        props: true,
        meta: {
          title: localStorage.getItem("support-token")
            ? "Панель специалиста поддержки"
            : "RC.Ticket - Служба поддержки"
        },
        component: localStorage.getItem("support-token")
          ? () => import("@/views/TicketSupport.vue")
          : () => import("@/views/TicketUser")
      }
    ],
    beforeEnter: userCheck
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (
    !(
      !localStorage.getItem("user-token") ||
      !localStorage.getItem("support-token")
    ) &&
    to.path !== "/sign-in"
  ) {
    next("/sign-in");
  } else {
    next();
  }
});

export default router;
