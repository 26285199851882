import Vue from "vue";
import Vuex from "vuex";
import ticket from "./ticket.store";
import status from "./status.store";
import source from "./source.store";
import user from "./user.store";
import theme from "./theme.store";
import message from "./message.store";
import oauth from "./oauth.store";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    support: true,
    openMenu: false,
    commentsVisible: false
  },
  mutations: {
    SET_MENU_STATE(state, payload) {
      state.openMenu = payload;
    },
    SET_COMMENTS_STATE(state, payload) {
      state.commentsVisible = payload;
    }
  },
  actions: {},
  modules: {
    ticket,
    status,
    source,
    user,
    theme,
    message,
    oauth
  },
  strict: debug
});
