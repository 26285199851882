import { API } from "@/main";

const state = {
  // userToken: localStorage.getItem("user-token") || "",
  supportToken: localStorage.getItem("support-token") || "",
  user: null,
  support: null
};

const getters = {
  // isAuthenticatedUser: state => state.userToken,
  isAuthenticatedSupport: state => state.supportToken
};

const actions = {
  auth() {
    return new Promise((resolve, reject) => {
      API.get("sanctum/csrf-cookie")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  login(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/support/auth/login", {
        email: data.email,
        password: data.password
      })
        .then(response => {
          localStorage.setItem("support-token", response.data.access_token);
          API.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.access_token;
          resolve(response);
        })
        .catch(error => {
          localStorage.removeItem("support-token");
          reject(error);
        });
    });
  },
  checkSupport({ commit }) {
    return new Promise((resolve, reject) => {
      API.get("api/support/auth/check")
        .then(response => {
          commit("SET_SUPPORT", response.data.data);
          resolve(response);
        })
        .catch(error => {
          commit("AUTH_LOGOUT_SUPPORT");
          reject(error);
        });
    });
  },
  checkUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get("api/clients/auth/check", {
        params: {
          source_id: data.id
        }
      })
        .then(response => {
          localStorage.setItem(
            "source",
            response.data.data.last_source.source_id
          );
          commit("SET_USER", response.data.data);
          resolve(response);
        })
        .catch(error => {
          commit("AUTH_LOGOUT");
          reject(error);
        });
    });
  },
  registerUser(_, data) {
    return new Promise((resolve, reject) => {
      API.post("api/clients/auth/register", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  AUTH_SUCCESS(state, token) {
    state.token = token;
  },
  AUTH_LOGOUT() {
    localStorage.clear();
    location.href = "/sign-in";
  },
  AUTH_LOGOUT_SUPPORT() {
    localStorage.clear();
    location.href = "/sign-in-support";
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_SUPPORT(state, payload) {
    state.support = payload;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  REDIRECT() {
    location.href = "/";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
