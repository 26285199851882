<template>
  <div
    id="menu"
    class="menu"
    :class="openMenu ? 'open' : 'close'"
    v-click-outside="onClickOutside"
  >
    <div class="menu__close">
      <button @click="setMenu(false)">
        <img src="../assets/icons/close.svg" alt="" />
      </button>
    </div>

    <ul class="menu__statuses">
      <li
        :class="['menu__item', status === 'new' ? 'menu__item--select' : '']"
        @click="selectStatus('new')"
      >
        Новые тикеты <span>{{ getStatusByName("new").count }}</span>
      </li>
      <li
        :class="[
          'menu__item',
          status === 'in-progress' ? 'menu__item--select' : ''
        ]"
        @click="selectStatus('in-progress')"
      >
        Тикеты в работе <span>{{ getStatusByName("in-progress").count }}</span>
      </li>
    </ul>

    <ul class="menu__list">
      <li
        :class="['menu__item', selected.id === '' ? 'menu__item--select' : '']"
        @click="selectMenuItem({ id: '', count: '' })"
      >
        Все
      </li>
      <li
        :class="[
          'menu__item',
          selected.id === item.id ? 'menu__item--select' : ''
        ]"
        v-for="item in themes"
        :key="item.id"
        @click="selectMenuItem(item)"
      >
        {{ item.name }}<span>{{ item.count }}</span>
      </li>
    </ul>

    <div class="menu__logout">
      <button @click="logout">
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H9C8.46957 0 7.96086 0.210714 7.58579 0.585786C7.21071 0.960859 7 1.46957 7 2V4C7 4.26522 7.10536 4.51957 7.29289 4.70711C7.48043 4.89464 7.73478 5 8 5C8.26522 5 8.51957 4.89464 8.70711 4.70711C8.89464 4.51957 9 4.26522 9 4V2H18V14H9V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12V14C7 14.5304 7.21071 15.0391 7.58579 15.4142C7.96086 15.7893 8.46957 16 9 16H18C18.5304 16 19.0391 15.7893 19.4142 15.4142C19.7893 15.0391 20 14.5304 20 14V2ZM4.707 4.293C4.51947 4.10553 4.26516 4.00021 4 4.00021C3.73484 4.00021 3.48053 4.10553 3.293 4.293L0.292999 7.293C0.105528 7.48053 0.000213623 7.73484 0.000213623 8C0.000213623 8.26516 0.105528 8.51947 0.292999 8.707L3.293 11.707C3.4816 11.8892 3.7342 11.99 3.9964 11.9877C4.2586 11.9854 4.50941 11.8802 4.69482 11.6948C4.88023 11.5094 4.9854 11.2586 4.98767 10.9964C4.98995 10.7342 4.88916 10.4816 4.707 10.293L3.414 9H13C13.2652 9 13.5196 8.89464 13.7071 8.70711C13.8946 8.51957 14 8.26522 14 8C14 7.73478 13.8946 7.48043 13.7071 7.29289C13.5196 7.10536 13.2652 7 13 7H3.414L4.707 5.707C4.89447 5.51947 4.99979 5.26516 4.99979 5C4.99979 4.73484 4.89447 4.48053 4.707 4.293Z"
            fill="#E4284A"
          />
        </svg>
        Выход
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { eventBus } from "@/main";

export default {
  name: "Menu",
  data() {
    return {
      selected: {
        name: "Все",
        id: "",
        count: ""
      },

      status: ""
    };
  },

  watch: {
    openMenu: function(newValue) {
      if (newValue) {
        this.loadThemes();
        this.loadStatuses();
      }
    }
  },

  computed: {
    ...mapState({
      openMenu: state => state.openMenu,
      themes: state => state.theme.themes
    }),

    ...mapGetters({
      getStatusByName: "status/getStatusByName"
    })
  },

  methods: {
    ...mapMutations({
      setMenu: "SET_MENU_STATE",
      logout: "oauth/AUTH_LOGOUT_SUPPORT"
    }),

    ...mapActions({
      loadThemes: "theme/loadThemes",
      loadStatuses: "status/loadStatuses"
    }),

    selectStatus(name) {
      setTimeout(() => {
        this.setMenu(false);
      }, 250);
      eventBus.$emit("changeStatus", {
        name: name
      });
    },

    selectMenuItem(item) {
      this.selected = item;
      setTimeout(() => {
        this.setMenu(false);
      }, 250);
      eventBus.$emit("changeTheme", {
        id: item.id
      });
    },

    onClickOutside(event) {
      if (event.target.id === "menuOpen") {
        this.setMenu(true);
      } else {
        this.setMenu(false);
      }
    }
  },

  mounted() {
    this.loadThemes();
  },

  created() {
    eventBus.$on("changeStatusFilter", data => {
      this.status = data.name;
    });
  }
};
</script>

<style lang="scss" scoped>
.open {
  left: 0;
}

.close {
  left: -380px;
}

.menu {
  position: fixed;
  top: 0;
  width: 380px;
  height: 100%;
  background: $label-main;
  z-index: 99999;
  padding: 16px 30px 16px 20px;
  transition: left 0.2s ease-out;

  &__close button {
    height: 40px;
    width: 40px;
    background: #ffffff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 999999;
  }

  &__list {
    margin-top: 40px;
    padding-left: 0;
    height: calc(100vh - 306px);
    overflow: auto;
  }

  &__statuses {
    margin-top: 40px;
    padding-left: 0;

    .menu__item {
      color: #a3a3a3;
      padding: 8px 10px;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 6px;
    }

    .menu__item--select {
      color: $main;
    }

    .menu__item {
      span {
        background-color: transparent;
        color: $third;
        font-weight: 500;
        font-size: 16px;
      }

      &--select {
        span {
          color: $label-third;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    color: #efefef;
    list-style: none;
    padding: 10px 16px;
    margin-bottom: 8px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    border-radius: 6px;

    span {
      font-size: 10px;
      line-height: 12px;
      color: $label-third;
      background-color: $label-four;
      border-radius: 4px;
      padding: 2px 5px;
      margin-left: 6px;
      font-weight: 600;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }

    &--select {
      background-color: #ffffff;
      color: $main;

      &:hover {
        background-color: #ffffff;
      }
    }
  }

  &__logout {
    height: 80px;
    display: flex;
    align-items: center;

    button {
      background: transparent;
      border: none;
      outline: none;
      color: #e4284a;
      font-size: 16px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}
</style>
